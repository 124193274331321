import React from 'react';
import styles from './index.module.scss';

export interface PlaceholderHeaderProps {

}

export const PlaceholderHeader = () => (
  <div className={styles.PlaceholderHeader}>
    <div className={styles.headerContainer}>
      <div className={styles.logo}>
        <a href="/" data-di-id="#/" className={styles.logoLink}>
          <span className={styles.hiddenText}>Lexus.com</span>
        </a>
      </div>
    </div>
  </div>
);

export default PlaceholderHeader;
