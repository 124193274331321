import React from 'react';
import styles from './index.module.scss';

export interface TopPagesProps {
  pages: Array<{
    href: string;
    label: string;
  }>;
}

export const TopPages = ({ pages }: TopPagesProps) => (
  <div className={styles.TopPages}>
    <h3 className={styles.header}>TOP PAGES</h3>
    <ul className={styles.list}>
      {
        pages.map((page, index) => (
          <li className={styles.listItem}>
            <a href={page.href} data-di-id="#/{page.href}" className={styles.link}>
                <span className={styles.index}>{index + 1}</span>
                <span className={styles.label}>{page.label}</span>
                <span className={styles.arrow}>▸</span>
            </a>
          </li>
        ))
      }
    </ul>
  </div>
);

export default TopPages;
