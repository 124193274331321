import React from 'react';

import cautionIcon from './caution-sign.png';
import styles from './index.module.scss';

export interface ErrorHeroProps {
  isGlobalNavLoaded: boolean;
  header: string;
  searchTitle: string;
}

export const ErrorHero = ({isGlobalNavLoaded, header, searchTitle}: ErrorHeroProps) => (
  <div className={styles.ErrorHero}>
    <div className={styles.backgroundImage} />
    <div className={styles.content}>
      <img className={styles.cautionIcon} src={cautionIcon} />
      <h1 className={styles.errorHeader}>{header}</h1>
      { isGlobalNavLoaded &&
      <>
      <hr className={styles.divider} />
      <h4 className={styles.searchTitle}>{searchTitle}</h4>
      <form className={styles.inputContainer} action="/search">
        <input name="q" id="search-bar" className={styles.searchInput} placeholder="Search" data-di-id="#search-bar-upper" />
        <button id="search-button" className={styles.searchButton} data-di-id="#search-button" value="" />
      </form>
      </>
      }
    </div>
  </div>
);

export default ErrorHero;
