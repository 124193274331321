import React, { Component } from 'react';

import ErrorHero from './components/ErrorHero';
import PlaceholderHeader from './components/PlaceholderHeader';
import TopPages from './components/TopPages';

import './App.css';
import './fonts/index.scss';

declare const window: any;

const data = {
  hero: {
    header: `YOU'VE FOUND OUR LIMITED-EDITION MISSING PAGE.`,
    searchTitle: 'Let us know what else you’re looking for.',
  },
  topPages: {
    list: [
      {
        href: '/dealers',
        label: 'Find a Dealer',
      },
      {
        href: '/build-your-lexus/#!/',
        label: 'Build Your Lexus',
      },
      {
        href: 'http://lexus2.custhelp.com/',
        label: 'FAQ',
      },
      {
        href: '/contact',
        label: 'Contact Us',
      },
      {
        href: '/lcertified',
        label: 'lexus pre-owned',
      },
    ],
  }
};

export interface AppProps {}
export interface AppState {
  isGlobalNavLoaded: boolean;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isGlobalNavLoaded: false,
    };
  }

  public componentDidMount() {
    const navScript = document.createElement('script');
    navScript.type = 'text/javascript';
    navScript.src = 'https://www.lexus.com/etc.clientlibs/lexus/clientlibs/resources/syndication/index.js';

    document.head.appendChild(navScript);
    navScript.onload = () => {
      this.setState({isGlobalNavLoaded: true});
      window.LEXUS.syndicate([ // Takes an array of injection configurations
        {
          component: 'GlobalNavigation',    // Allowable component names
          key: 'header',                    // Key under the model.json responsivegrid.:items
          target: '#header-module',         // Selector for the injection site
        },
        {
          component: 'GlobalFooter',        // Example for footer injection
          key: 'footer',
          target: '#footer-module',
        }
        ],
        '/privacy/partner-page.model.json?pn=y&cb=lcom404&t'
      );
    };
  }

  public render() {
    const {isGlobalNavLoaded} = this.state;
    return (
      <div className="App">
        {!isGlobalNavLoaded && <PlaceholderHeader />}
        <ErrorHero {...data.hero} isGlobalNavLoaded={isGlobalNavLoaded} />
        {isGlobalNavLoaded && <TopPages pages={data.topPages.list} />}
      </div>
    );
  }
}

export default App;
